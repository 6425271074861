import React from 'react';
import Header from '../components/Header/Header';

const GlobalLayout = props => {
    return (
        <div className="globalLayout">
            <Header />
                {props.children}
        </div>
    );
}

export default GlobalLayout;