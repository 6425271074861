import React from 'react';
import DashboardMenu from '../components/DashboardMenu/DashboardMenu';
import DashboardBody from '../components/DashboardBody/DashboardBody';
import GlobalLayout from '../layouts/GlobalLayout';

// Components
import Title from "../components/Title/Title";

const Dashboard = () => {
    return (
        <>
            <Title>Dashboard</Title>
            <GlobalLayout>
                <div className="dashboardContainer d-flex">
                    <DashboardMenu />
                    <DashboardBody />
                </div>
            </GlobalLayout>
        </>
    );
}

export default Dashboard;
