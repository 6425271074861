import React from "react"
import OrderInfo from "./OrderInfo/OrderInfo";

const DashboardBody = () => {
  return (
    <div className="dashboardBody flex-grow-1 h-100 pt-3">
      <OrderInfo />
    </div>
  )
}

export default DashboardBody
