import React from "react"
import { Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFunnelDollar, faSmile, faTag, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

const DashboardMenu = () => {
  return (
    <div className="dashboardMenu h-100 pt-3">
      <Nav defaultActiveKey="/home" className="flex-column">
        <Nav.Link href="#"><FontAwesomeIcon className="mr-2" icon={faFunnelDollar} /> Ordini</Nav.Link>
        <Nav.Link href="#"><FontAwesomeIcon className="mr-2" icon={faSmile} /> Bestsellers</Nav.Link>
        <Nav.Link href="#"><FontAwesomeIcon className="mr-2" icon={faTag} /> Coupons</Nav.Link>
        <Nav.Link href="/"><FontAwesomeIcon className="mr-2" icon={faSignOutAlt} /> Logout</Nav.Link>
      </Nav>
    </div>
  )
}

export default DashboardMenu
