import { GlobalContext } from "../../../context/GlobalContext"; // Context globale.
import moment from 'moment-timezone';

import React, { useContext, useState } from "react"
import { Card, Button } from 'react-bootstrap';

// Logica & Funzioni componente.
import OrderInfoModel from './OrderInfoModel';

const OrderInfo = () => {

  // useContext & useState, quì verranno settate le variabili relative allo stato del componente.
  const { isLoading, setIsLoading, isError, setIsError, tokenKey, webSite } = useContext(GlobalContext);
  const [isTotal, setIsTotal] = useState({});
  const [isTotalRefunds, setIsTotalRefunds] = useState({});

  const [isStartDate, setStartDate] = useState();
  const [isEndDate, setEndDate] = useState();

  // Funzioni Componente
  const handleGrandTotal = async () => setIsTotal(await OrderInfoModel.fetchOrders(webSite, tokenKey, setIsLoading, setIsError, isStartDate, isEndDate)); // Eseguo chiamata API per creazione variabili.
  const handleRefundsTotal = async () => setIsTotalRefunds(await OrderInfoModel.fetchRefunds(webSite, tokenKey, setIsLoading, setIsError, isStartDate, isEndDate)); // Eseguo chiamata API per creazione Rimborsi.

  const handleStartDate = e => {
    // Effettuo il parse della data, perchè magento calcola le date in formato UTC.
    var dateTime = moment.tz(e.target.value, "Europe/Rome");
    setStartDate(dateTime.utc().format().replace('T',' ').replace('Z', ''));
  }
  const handleEndDate = e => setEndDate(e.target.value);

  return (
    <div className="OrderInfo">
      <Card style={{ width: "35rem" }}>
        <Card.Body>
          <Card.Title>Fatturato, seleziona l'intervallo.</Card.Title>
          
          <div className="dateHolders">
            <input onChange={handleStartDate} aria-label="Start Date" type="date" />
            <input onChange={handleEndDate} aria-label="End Date" className="ml-3" type="date" />
          </div>

          <div className="bodyResults fatturatoVariabli py-3">
          <h5 className="pt-2">Totale Fatturato:</h5>
          {isTotal.eur && isTotal.eur.map((item,index) => (
            <div key={index} className="euroResults border-top border-bottom pt-3">
              <p>Euro Lordo: <b>{Math.round(item.lor)} €</b></p>
              <p>Euro Netto: <b>{Math.round(item.net)} €</b></p>
            </div>
           ))}

          {isTotal.gbp && isTotal.gbp.map((item,index) => (
            <div key={index} className="sterlingResults border-bottom pt-3">
              <p>Sterline Lordo: <b>{Math.round(item.lor)} ₤</b></p>
              <p>Sterline Netto: <b>{Math.round(item.net)} ₤</b></p>
            </div>
           ))}

          {isTotal.usd && isTotal.usd.map((item,index) => (
            <div key={index} className="dollarResults border-bottom pt-3">
              <p>Dollari Lordo: <b>{Math.round(item.lor)} $</b></p>
            </div>
           ))}
          </div>

          <div className="bodyResults rimborsiVariabili py-3">
          <h5 className="pt-2">Totale Rimborsi:</h5>
          {isTotalRefunds.eur && isTotalRefunds.eur.map((item,index) => (
            <div key={index} className="euroResults border-top border-bottom pt-3">
              {console.log(item.lor)}
              <p>Euro Lordo: <b>{Math.round(item.lor)} €</b></p>
              <p>Euro Netto: <b>{Math.round(item.net)} €</b></p>
            </div>
           ))}

          {isTotalRefunds.gbp && isTotalRefunds.gbp.map((item,index) => (
            <div key={index} className="sterlingResults border-bottom pt-3">
              <p>Sterline Lordo: <b>{Math.round(item.lor)} ₤</b></p>
              <p>Sterline Netto: <b>{Math.round(item.net)} ₤</b></p>
            </div>
           ))}

          {isTotalRefunds.usd && isTotalRefunds.usd.map((item,index) => (
            <div key={index} className="dollarResults border-bottom pt-3">
              <p>Dollari Lordo: <b>{Math.round(item.lor)} $</b></p>
            </div>
           ))}
          </div>
          
          <Button className="mr-3" onClick={handleGrandTotal} variant="warning">Calcola Variabili</Button>
          <Button className="mr-3" onClick={handleRefundsTotal} variant="warning">Calcola Rimborsi</Button>
        </Card.Body>

        {isLoading && <div className="py-3 px-3">Caricamento, attendere prego.</div>}
        {isError && <div className="py-3 px-3">Si è verificato un errore, hai usato delle variabili sbagliate?</div>}

      </Card>
    </div>
  )
}

export default OrderInfo
