import Websites from "../../../utils/Websites";
import CountryCodes from "../../../utils/CountryCodes";
import Axios from "axios"; // Libreria Http.

const OrderInfoModel = {

  /**
  * @desc Fetch Orders.
  * @param webSite, tokenKey, setIsLoading, setIsError, isStartDate, isEndDate
  * @return Obj.
  */
  
  // Funzione per calcolare totale fatturato.
  fetchOrders: async (webSite, tokenKey, setIsLoading, setIsError, isStartDate, isEndDate) => {

    var totalCurrencies = {};
    const ApiConfig = { // Oggetto per configurazione API.
      method: "get",
      url: `${Websites.CorsURL}${webSite}rest/V1/orders?
        searchCriteria[filter_groups][0][filters][0][field]=status&
        searchCriteria[filter_groups][0][filters][0][value]=complete&
        searchCriteria[filter_groups][0][filters][1][field]=status&
        searchCriteria[filter_groups][0][filters][1][value]=closed&
        searchCriteria[filter_groups][0][filters][2][field]=status&
        searchCriteria[filter_groups][0][filters][2][value]=pending&
        searchCriteria[filter_groups][0][filters][3][field]=status&
        searchCriteria[filter_groups][0][filters][3][value]=processing&
        searchCriteria[filter_groups][0][filters][4][field]=status&
        searchCriteria[filter_groups][0][filters][4][value]=processing_exported&
        searchCriteria[sortOrders][0][field]=increment_id&
        searchCriteria[filter_groups][1][filters][0][field]=created_at&
        searchCriteria[filter_groups][1][filters][0][value]=${isStartDate}&
        searchCriteria[filter_groups][1][filters][0][condition_type]=gteq&
        searchCriteria[filter_groups][2][filters][0][field]=created_at&
        searchCriteria[filter_groups][2][filters][0][value]=${isEndDate} 23:59:59&
        searchCriteria[filter_groups][2][filters][0][condition_type]=lteq&
        fields=items[base_currency_code,grand_total,billing_address]`,
      headers: {
        Authorization: "Bearer " + tokenKey,
        "Content-Type": "application/json",
      },
    }

    setIsLoading(true);
    setIsError(false);

    // Api Call.
    await Axios(ApiConfig)
      .then(response => {
        console.log(response);
        totalCurrencies = OrderInfoModel.calculateGrandTotals(response.data.items);
      })
      .catch(error => {
        console.log(error);
        setIsError(true);
      })

    setIsLoading(false);
    return totalCurrencies;
  },

  /**
  * @desc Logica calcolo Fatturato.
  * @param ordersList
  * @return Obj.
  */

  calculateGrandTotals: (ordersList) => {
    var currenciesList = { // Variabile per somma fatturato.
      eur: [{ lor: 0, net: 0 }],
      usd: [{ lor: 0 }],
      gbp: [{ lor: 0, net: 0 }]
    };

    var euroOrders = [];
    var dollarOrders = [];
    var sterlingOrders = [];

    ordersList.map(item => {
      // Gestione Array di ordini differeniati.
      if (item.base_currency_code === 'USD') { return dollarOrders.push(item);} 
      else if (item.base_currency_code === 'EUR') { return euroOrders.push(item); }
      else if (item.base_currency_code === 'GBP') { return sterlingOrders.push(item); }

      return [euroOrders, dollarOrders, sterlingOrders];
    });

    dollarOrders.map(item => currenciesList.usd[0].lor += item.grand_total); // Mappatura array USD.

    sterlingOrders.map(item => {
      currenciesList.gbp[0].lor += item.grand_total;
      currenciesList.gbp[0].net += (item.grand_total / 1.22);

      return currenciesList;
    }); // Mappatura array GBP.

    euroOrders.map(item => {
      if (CountryCodes.includes(item.billing_address.country_id)) {
        currenciesList.eur[0].lor += item.grand_total; // Aggiungo il valore lordo, se fa parte dell'unione europea.
        currenciesList.eur[0].net += (item.grand_total / 1.22); // Sottraggo il valore dell'iva, se fà parte dell'unione euorpea.
      } else {
        // Se non fà parte dell'unione europea, netto e lordo sono uguali, e vanno aggiunti allo stesso modo.
        currenciesList.eur[0].lor += item.grand_total;
        currenciesList.eur[0].net += item.grand_total;
      }
      return currenciesList;
    }); // Mappatura array EUR.

    return currenciesList;
    
  },

  /**
  * @desc Fetch Refunds.
  * @param ordersList
  * @return Obj.
  */

  // Funzione per calcolare totale fatturato.
  fetchRefunds: async (webSite, tokenKey, setIsLoading, setIsError, isStartDate, isEndDate) => {

    var totalCurrenciesRefunds = {};
    const ApiConfig = { // Oggetto per configurazione API.
      method: "get",
      url: `${Websites.CorsURL}${webSite}rest/V1/creditmemos?
        searchCriteria[filter_groups][0][filters][0][field]=created_at&
        searchCriteria[filter_groups][0][filters][0][value]=${isStartDate}&
        searchCriteria[filter_groups][0][filters][0][condition_type]=gteq&
        searchCriteria[filter_groups][1][filters][0][field]=created_at&
        searchCriteria[filter_groups][1][filters][0][value]=${isEndDate} 23:59:59&
        searchCriteria[filter_groups][1][filters][0][condition_type]=lteq&
        fields=items[order_id,base_currency_code,grand_total]`,
      headers: {
        Authorization: "Bearer " + tokenKey,
        "Content-Type": "application/json",
      },
    }

    setIsLoading(true);
    setIsError(false);

    // Api Call.
    await Axios(ApiConfig)
      .then(async response => {
        totalCurrenciesRefunds = await OrderInfoModel.calculateRefunds(response.data.items, webSite, tokenKey, setIsLoading, setIsError);
      })
      .catch(error => {
        console.log(error);
        setIsError(true);
      })

    setIsLoading(false);

    return totalCurrenciesRefunds;
  },

  /**
  * @desc Logica calcolo Refunds
  * @param ordersList
  * @return Obj.
  */

 calculateRefunds: async (ordersList, webSite, tokenKey) => {
  var currenciesRefundsList = { // Variabile per somma rimborsi.
    eur: [{ lor: 0, net: 0 }],
    usd: [{ lor: 0 }],
    gbp: [{ lor: 0, net: 0 }]
  };

  var euroRefundsOrders = [];
  var dollarRefundsOrders = [];
  var sterlingRefundsOrders = [];

  ordersList.map(item => {
    // Gestione Array di ordini differeniati.
    if (item.base_currency_code === 'USD') { return dollarRefundsOrders.push(item);} 
    else if (item.base_currency_code === 'EUR') { return euroRefundsOrders.push(item); }
    else if (item.base_currency_code === 'GBP') { return sterlingRefundsOrders.push(item); }

    return [euroRefundsOrders, dollarRefundsOrders, sterlingRefundsOrders];
  });

  dollarRefundsOrders.map(item => currenciesRefundsList.usd[0].lor += item.grand_total); // Mappatura array USD.

  sterlingRefundsOrders.map(item => {
    currenciesRefundsList.gbp[0].lor += item.grand_total;
    currenciesRefundsList.gbp[0].net += (item.grand_total / 1.22);

    return currenciesRefundsList;
  }); // Mappatura array GBP.

  // Chiamata API, per ogni item ciclato. Utilizzo Promise.all, perchè ogni ciclo è una chiamata async, quindi con l'utilizzo di Promise.all eseguo il codice successivo,
  // Solo e solamente se tutte le promise richiamate si sono risolte con successo.
  await Promise.all(euroRefundsOrders.map(async item => {
    const ApiConfig = { // Oggetto per configurazione API.
      method: "get",
      url: `${Websites.CorsURL}${webSite}rest/V1/orders/${item.order_id}`,
      headers: {
        Authorization: "Bearer " + tokenKey,
        "Content-Type": "application/json",
      },
    }

    await Axios(ApiConfig)
    .then(response => { 
      if (CountryCodes.includes(response.data.billing_address.country_id)) {
        currenciesRefundsList.eur[0].lor += item.grand_total; // Aggiungo il valore lordo, se fa parte dell'unione europea.
        currenciesRefundsList.eur[0].net += (item.grand_total / 1.22); // Sottraggo il valore dell'iva, se fà parte dell'unione euorpea.
      } else {
        // Se non fà parte dell'unione europea, netto e lordo sono uguali, e vanno aggiunti allo stesso modo.
        currenciesRefundsList.eur[0].lor += item.grand_total;
        currenciesRefundsList.eur[0].net += item.grand_total;
      }

      return currenciesRefundsList;
    })
    .catch(error => { console.log(error); })
  })); // Mappatura array EUR.

  return currenciesRefundsList;
  
 }

}

export default OrderInfoModel
