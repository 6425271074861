import React from 'react';
import Logo from '../../../static/logoIkom.png';


const Header = () => {
    return (
        <div className="ikomGlobalHeader text-left px-2 py-2">
            <img className="logoIkom" src={Logo} alt="logoIkom" />
        </div>
    );
}

export default Header;